<template>
  <div class="h5">
    <img src="~img/h5/h5_01.jpg" alt="">
    <img src="~img/h5/h5_02.jpg" alt="">
    <div class="one_form">
      <img src="~img/h5/h5_03.jpg" alt="">
      <div class="datas">
        <p>你想要领取(多选)</p>
        <ul class="check">
          <li v-for="item in checkboxList" :key="item.id">
            <input type="checkbox" :value="item.id" @change="changeCheck(item.name)">
            <span>{{item.name}}</span>
          </li>
        </ul>
        <div class="text">
          <input type="text" name="name" placeholder="请输入您的姓名" v-model="name">
          <input type="tel" name="mobile" placeholder="请输入您的电话" v-model="mobile">
          <div class="eme_se">
            <el-select v-model="provinceValue" placeholder="请选择省" @change="selectProvimce">
              <el-option v-for="(item,index) of provincearr" :key="index" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
            <el-select v-model="cityValue" placeholder="请选择市" @change="selectcity">
              <el-option v-for="(item,index) of cityarr" :key="index" :label="item.name" :value="item.name"></el-option>
            </el-select>
            <el-select placeholder="请选择区/县" v-model="RegionValue">
              <el-option v-for="(item,index) of regionarr" :key="index" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </div>
          <button @click="submitForm">
            免费领取项目资料
          </button>
        </div>
      </div>
    </div>
    <img src="~img/h5/h5_04.jpg" alt="">
    <div class="one_form tow_form">
      <img src="~img/h5/h5_05.jpg" alt="">
      <div class="datas">
        <p>你想要领取(多选)</p>
        <ul class="check">
          <li>
            <input type="checkbox" name="check[]" value="项目资料">
            <span>项目资料</span>
          </li>
          <li>
            <input type="checkbox" name="check[]" value="成本利润分析">
            <span>成本利润分析</span>
          </li>
          <li>
            <input type="checkbox" name="check[]" value="项目考察名额">
            <span>项目考察名额</span>
          </li>
          <li>
            <input type="checkbox" name="check[]" value="业内培训">
            <span>业内培训</span>
          </li>
        </ul>
        <div class="text">
          <input type="text" name="name" placeholder="请输入您的姓名" v-model="name">
          <input type="tel" name="mobile" placeholder="请输入您的电话" v-model="mobile">
          <div class="eme_se">
            <el-select v-model="provinceValue" placeholder="请选择省" @change="selectProvimce">
              <el-option v-for="(item,index) of provincearr" :key="index" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
            <el-select v-model="cityValue" placeholder="请选择市" @change="selectcity">
              <el-option v-for="(item,index) of cityarr" :key="index" :label="item.name" :value="item.name"></el-option>
            </el-select>
            <el-select placeholder="请选择区/县" v-model="RegionValue">
              <el-option v-for="(item,index) of regionarr" :key="index" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </div>
          <button @click="submitForm">
            免费领取项目资料
          </button>
        </div>
      </div>
    </div>
    <img src="~img/h5/h5_06.jpg" alt="">
    <img src="~img/h5/h5_07.jpg" alt="">
    <img src="~img/h5/h5_08.jpg" alt="">
    <div class="one_form">
      <img src="~img/h5/h5_09.jpg" alt="">
      <div class="datas">
        <p>你想要领取(多选)</p>
        <ul class="check">
          <li>
            <input type="checkbox" name="check[]" value="项目资料">
            <span>项目资料</span>
          </li>
          <li>
            <input type="checkbox" name="check[]" value="成本利润分析">
            <span>成本利润分析</span>
          </li>
          <li>
            <input type="checkbox" name="check[]" value="项目考察名额">
            <span>项目考察名额</span>
          </li>
          <li>
            <input type="checkbox" name="check[]" value="业内培训">
            <span>业内培训</span>
          </li>
        </ul>
        <div class="text">
          <input type="text" name="name" placeholder="请输入您的姓名" v-model="name">
          <input type="tel" name="mobile" placeholder="请输入您的电话" v-model="mobile">
          <div class="eme_se">
            <el-select v-model="provinceValue" placeholder="请选择省" @change="selectProvimce">
              <el-option v-for="(item,index) of provincearr" :key="index" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
            <el-select v-model="cityValue" placeholder="请选择市" @change="selectcity">
              <el-option v-for="(item,index) of cityarr" :key="index" :label="item.name" :value="item.name"></el-option>
            </el-select>
            <el-select placeholder="请选择区/县" v-model="RegionValue">
              <el-option v-for="(item,index) of regionarr" :key="index" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </div>
          <button @click="submitForm">
            免费领取项目资料
          </button>
        </div>
      </div>
    </div>
    <img src="~img/h5/h5_10.jpg" alt="">
  </div>
</template>

<script>
import { province } from "../assets/map";
import wx from "weixin-js-sdk";

export default {
  data() {
    return {
      provincearr: "",
      arr: [1, 3, 4],
      province: [],
      cityarr: [],
      regionarr: [],
      provinceValue: "", // 省
      cityValue: "", // 市
      RegionValue: "", // 区
      checkboxList: [
        {
          name: "项目资料",
          id: 1,
        },
        {
          name: "成本利润分析",
          id: 2,
        },
        {
          name: "项目考察名额",
          id: 3,
        },
        {
          name: "业内培训",
          id: 4,
        },
      ],
      seleID: [], // 多选
      mobile: "", // 电话
      name: "", // 姓名
    };
  },
  created() {
    this.provincearr = province;
    this.getWxJSSDK();
  },
  methods: {
    async submitForm() {
      const res = await this.axios.post("api/datas/submit", {
        check: this.seleID,
        name: this.name,
        mobile: this.mobile,
        region:
          this.provinceValue + " " + this.cityValue + " " + this.RegionValue,
      });
      if (res.status == 200 && res.data.code == 0) {
        this.$toast(res.data.msg);
      } else {
        this.$toast(res.data.msg);
      }
    },
    changeCheck(name) {
      const findIndex = this.seleID.findIndex((val) => val == name);
      if (findIndex === -1) {
        this.seleID.push(name);
      } else {
        this.seleID.splice(findIndex, 1);
      }
      console.log(findIndex);
      console.log(this.seleID);
    },
    selectProvimce(id) {
      this.cityarr = [];
      this.regionarr = [];
      this.cityValue = "";
      this.RegionValue = "";
      for (let item of this.provincearr) {
        if (id == item.name) {
          this.cityarr = item.children;
          console.log(this.cityarr);
        }
      }
      console.log(this.provinceValue);
    },
    selectcity(id) {
      this.regionarr = [];
      this.RegionValue = "";
      for (let item of this.cityarr) {
        if (id == item.name) {
          this.regionarr = item.children;
        }
      }
    },
    async getWxJSSDK() {
      let that = this;
      const res = await this.axios.post(`api/datas/share`, {
        url: window.location.href,
      });
      console.log(res);
      if (res.status === 200) {
        // wx.wxRegister(res.data.data, this.option);
        (function () {
          let data = res.data;
          // console.log(that);
          console.log(data);
          var appId = data.appId;
          var timestamp = data.timestamp;
          var nonceStr = data.nonceStr;
          var signature = data.signature;
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId, // 必填，公众号的唯一标识，填自己的！
            timestamp: timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
            nonceStr: nonceStr, // 必填，生成签名的随机串
            signature: signature, // 必填，签名，见附录1
            jsApiList: [
              "updateTimelineShareData", //分享给好友
              "updateAppMessageShareData", //分享到朋友圈
            ],
          });
          wx.ready(function () {
            //分享到朋友圈
            wx.updateTimelineShareData({
              title: "爱因斯坦思维科学馆全国招募城市合伙人！",
              link: location.href, // 分享时的链接
              imgUrl: "http://www.einstein.top/40.jpg",
              success: function success() {
                console.log("分享成功");
                // that.getshare();
              },
              cancel: function cancel() {
                console.log("取消分享");
              },
            });
            //分享给朋友
            wx.updateAppMessageShareData({
              link: location.href, // 分享时的链接
              desc: "抢占教培行业刚需蓝海赛道，打造千万营收校区！",
              title: "爱因斯坦思维科学馆全国招募城市合伙人！",
              imgUrl: "http://www.einstein.top/40.jpg",
              success: function success() {
                console.log("分享成功");
                // that.getshare();
              },
              cancel: function cancel() {
                console.log("取消分享");
              },
            });
          });
        })();
      }
    },
  },
};
</script>

<style scoped lang="less">
.h5 {
  margin: auto;
}
.eme_se {
  display: flex;
  padding-right: 0.6rem;
}
.eme_se {
  /deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #000;
  }
  /deep/.el-input__icon {
    line-height: 30px;
  }
}
.one_form {
  position: relative;
}
.one_form .datas {
  width: 6.72rem /* 672/50 */;
  height: 6.87rem;
  background-color: hotpink;
  position: absolute;
  top: 2.8rem;
  left: 50%;
  transform: translate(-50%);
  background: rgba(27, 88, 231, 0.4);
  border-radius: 0.4rem;
  padding-left: 0.4rem;
  padding-top: 0.4rem;
}
.one_form .datas p {
  color: #fff;
  font-size: 0.3rem;
}
.one_form .datas .check {
  overflow: hidden;
  margin-top: 0.3rem;
}
.one_form .datas .check li {
  float: left;
  width: 50%;
  margin-bottom: 0.2rem;
  height: 0.5rem;
  line-height: 0.4rem;
  position: relative;
}
.one_form .datas .check li input {
  width: 0.4rem;
  height: 0.4rem;
  background-color: #fff;
  margin-right: 0.1rem;
}
.one_form .datas .check li span {
  display: inline-block;
  color: #fff;
  font-size: 0.3rem;
  position: absolute;
  top: 0;
}
.one_form .datas .text {
  margin-top: 0.3rem;
}
.one_form .datas .text input {
  width: 90%;
  height: 0.6rem;
  border: 1px solid #cccccc;
  border-radius: 0.1rem;
  margin-bottom: 0.3rem;
  padding-left: 0.15rem;
}
.one_form .datas .text select {
  width: 30%;
  height: 0.6rem;
  border-radius: 0.1rem;
  float: left;
}
.one_form .datas .text button {
  width: 85%;
  height: 0.6rem;
  position: absolute;
  bottom: 0.4rem;
  left: 50%;
  transform: translate(-52%);
  background-color: #f8d765;
  color: #2b4cb9;
}
.tow_form .datas {
  top: 5.6rem;
}
</style>